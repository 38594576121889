import React                      from 'react'
import { navigate }               from 'gatsby'
import CssBaseline                from '@material-ui/core/CssBaseline'
import { withStyles }             from '@material-ui/core/styles'
import Typography                 from '@material-ui/core/Typography'
import Container                  from '@material-ui/core/Container'

import Copyright                  from '../Copyright'
import Form                       from './Form'

import { handleLogin, isLoggedIn } from '../../utils/auth'

const styles = (theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(10),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    margin: theme.spacing(1, 0),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
})

class Landing extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
    }
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    handleLogin(this.state)
  }

  render() {
    const { classes } = this.props
    if (isLoggedIn()) navigate('/proposal')

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h3">
            ¡Hola!
          </Typography>
          <Form
            handleUpdate={(e) => this.handleUpdate(e)}
            handleSubmit={(e) => this.handleSubmit(e)}
            style={{ form: classes.form, submit: classes.submit }}
          />
          <Copyright />
        </div>
      </Container>
    )
  }

}

export default withStyles(styles)(Landing)
