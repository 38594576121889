import React                    from 'react'

import Button                   from '@material-ui/core/Button'
import TextField                from '@material-ui/core/TextField'

export default ({ handleSubmit, handleUpdate, style }) => (
  <form
    className={style.form}
    method="post"
    onSubmit={(event) => {
      handleSubmit(event)
    }}
  >
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      id="username"
      label="Usuario"
      name="username"
      onChange={handleUpdate}
    />
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      name="password"
      label="Contraseña"
      type="password"
      id="password"
      onChange={handleUpdate}
    />
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={style.submit}
      value="Log In"
    >
      Entrar
    </Button>
  </form>
)
